// Import vendor libraries from node_modules here
@import '../../node_modules/bootstrap/dist/css/bootstrap';
@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';

// Base classes are fundamental building blocks based on the site design
@import 'base/reset';
@import 'base/breakpoints';
@import 'base/typography';
@import 'base/colors';
@import 'base/layout';
@import 'base/spacing';

// UI Components are generic and reusable, and only relies on base and vendor css
@import 'ui_components/buttons';
@import 'ui_components/modal';
@import 'ui_components/video';

// Default page settings
html {
  font-size: 16px;

  // Since rem is used for all font, height, and width measurements,
  // this decreases the spacing when viewed on a small screen
  @include mobile {
    font-size: 14px;
  }

  // Needed for smooth scroll on iOS
  -webkit-overflow-scrolling: touch;
}

body {
  @extend .t4;
  @extend .color-black;
  @extend .flex-col;

  font-family: Lato;
  overflow-x: hidden;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border: none;
  border-color: $teal;
  background-color: $teal;
  color: white;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(.disabled):hover {
  background-color: white;
  color: #6c757d;
  border-color: #dfe2e6;
  box-shadow: none !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(.disabled):hover {
  border-color: $teal !important;
  background-color: $teal !important;
  color: white !important;
  box-shadow: none !important;
}

.headline {
  font-weight: 400;
}

.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

nav {
  box-shadow: 0 0px 0.1px 0px;
}

.nav-margin {
  margin-top: 2rem !important;
}

.cta-button {
  @media (min-width: 568px) {
    max-width: 180px;
  }
  width: 100%;
  height: 50px;
  border-radius: 4px;
  color: white !important;
  border: none !important;
  background: #00cfcc !important;
}

.toolkit-button {
  color: #fff !important;
  background: #00cfcc !important;
  border: 0 !important;
  border-radius: 100px !important;
  position: relative !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 18px !important;
  padding: 17px 34px !important;
  transition: background 200ms !important;
  width: 100% !important;
  outline: 0 !important;
  user-select: none !important;

  &:focus {
    border: 0;
  }

  &:disabled {
    border: 0;
    color: #fff;
    background: #dbd9d8;
  }
}

.fixed-top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.rc-slider-disabled {
  background-color: inherit !important;
}

.rc-slider-disabled > .rc-slider-handle {
  background-color: #e9e9e9 !important;
}

.rc-slider.has-marks {
  margin-bottom: 18px;
}

.rc-slider .rc-slider-mark {
  top: 22px;
}

.rc-slider-mark span {
  color: #babecd;
  white-space: nowrap;
}

.rc-slider-mark span:first-child[style] {
  transform: none !important;
}

.rc-slider-mark span:last-child[style] {
  transform: translateX(-15%) !important;
}

body.modal-open {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
}

.page-flex-layout {
  display: flex;
  flex-direction: column;
}

.height-auto {
  height: auto;
}
