.video-play-button {
  z-index: 1001;
  width: 80px;
  height: 80px;
  background-color: #4454bf;
  border-radius: 40px;
  box-shadow: 0px 4px 14px 4px rgba(0, 0, 0, 0.1);
}
.video-play-button:hover {
  background-color: #3c4baa;
}
.video-play-button-triangle {
  position: relative;
  left: 3px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;

  border-left: 24px solid #ffffff;
  margin: auto;
}

.video-background {
  // background-color: #eef4ff;
}
@include media-breakpoint-down(sm) {
  .video-background {
    height: 200px;
  }
}

.video-play {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.video-modal-body {
  padding: 0px;
}

.video-modal-backdrop {
  opacity: 0.85 !important;
}

.video-modal {
  max-width: 800px !important;
  width: 100% !important;
  font-family: Lato;
}
@include media-breakpoint-down(md) {
  .video-modal {
    margin: 0px;
  }
}
