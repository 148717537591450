.modal {
  color: #5c5273;
}

.modal-dialog {
  max-width: 98% !important;
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 900px !important;
  }
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0 -3rem 0 auto;
  text-align: center;
}

.no-toggle .modal-title {
  margin-left: auto;
  margin-right: auto;
}

.title-long {
  display: none;
}

.title-short {
  display: block;
}

.close {
  font-size: 2rem !important;
  text-shadow: none !important;
  outline: none !important;
}

.modal-body {
  font-size: 1.1rem !important;
}

.modal-footer {
  border-top: 0 !important;
  padding: 1.5rem 1rem !important;
}
.third-party-auth-col-wrapper.no-pad,
.auth-col-wrapper.no-pad {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
}

.auth-col-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  border-top: 1px solid #d6e1e4;
  padding-top: 2rem;
}

.top-divider {
  border-top: 1px solid #d6e1e4;
  padding-top: 2rem;
}

.left-or {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: calc(50% - 1rem);
  z-index: 2;
  background: #fff;
  color: #d6e1e4;
  font-size: 1.1rem;
  display: none;
}

.top-or {
  z-index: 2;
  position: absolute;
  left: calc(50% - 1rem);
  top: -0.75rem;
  border-left: 0.55rem solid #fff;
  border-right: 0.55rem solid #fff;
  background: #fff;
  color: #d6e1e4;
  font-size: 1.1rem;
}

.auth-input {
  border: 0;
  color: #5c5273;
  border-bottom: 2px solid #d6e1e4;
  width: 100%;
  margin-bottom: 1rem;
}

.auth-input.error {
  color: red;
  border-bottom: 2px solid red;
}

.error-msg {
  color: red;
}

.auth-input::placeholder {
  color: #99a0a2;
}

.auth-input.error::placeholder {
  color: red;
}

.auth-input:focus {
  border-bottom: 2px solid #d6e1e4;
}

.auth-form-submit-button.disabled:hover {
  border: none;
}

.auth-form-submit-button.disabled {
  margin-top: 0;
  width: 100%;
  height: 4rem;
  font-size: 1.1rem !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.auth-form-submit-button {
  margin-top: 0;
  color: #fff !important;
  font-size: 1.1rem !important;
  width: 100%;
  height: 4rem;
  background-color: #00cfcc !important;
}

.third-party-auth-button {
  height: 4rem;
  line-height: normal;
  padding: 0.5rem 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
  border-color: #dfe2e6 !important;
}

.third-party-auth-button:hover {
  border-color: #dfe2e6 !important;
}

.third-party-auth-button-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}

.third-party-auth-button-icon {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.third-party-auth-button-text {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
  padding-left: 1rem;
  font-size: 1.1rem;
}

.already-member {
  margin-top: 1.5rem;
  text-align: center;
}

.already-member.left {
  display: none;
}

.not-member {
  margin-top: 1.5rem;
  text-align: center;
}

.not-member.left {
  display: none;
}

.forgot-password {
  text-align: center;
  margin-top: 1.5rem;
}

.forgot-password > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.terms-section {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 300;
}

.terms-section.left {
  display: none;
}

.terms-link {
  font-size: 1rem;
}

.terms-link:hover {
  color: #00cfcc;
}

[class*='col-'].no-lr-pad-sm-down {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  [class*='col-'].no-lr-pad-sm-down {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-title {
    margin-top: 2rem;
  }

  .title-long {
    display: block;
  }
  .title-short {
    display: none;
  }

  .third-party-auth-col-wrapper {
    margin-top: 4rem;
    padding: 0 3rem 0 2rem;
    text-align: center;
  }

  .auth-col-wrapper {
    padding: 4rem 2rem 0 3rem;
    border-top: 0;
  }

  .auth-col-wrapper.top-divider {
    border-top: 1px solid #d6e1e4;
    padding-top: 2rem;
  }

  .left-divider {
    border-left: 1px solid #d6e1e4;
  }

  .top-divider-spacer {
    margin-top: 2rem;
  }

  .top-or {
    top: -1rem;
  }

  .top-or.sign-up {
    display: none;
  }

  .left-or {
    display: block;
  }

  .auth-form-submit-button,
  .auth-form-submit-button.disabled {
    font-weight: 600;
    margin: 1rem 0 0;
  }

  .already-member {
    display: none;
  }

  .already-member.left {
    display: block;
    margin-top: 0;
  }

  .not-member {
    display: none;
  }

  .not-member.left {
    display: block;
    margin-top: 0;
  }

  .terms-section.left {
    display: block;
  }

  .terms-section.bottom {
    display: none;
  }

  .no-l-pad {
    padding-left: 0 !important;
  }

  .no-r-pad {
    padding-right: 0 !important;
  }
}
