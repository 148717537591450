$mobile-small-width: 480px;
$mobile-width: 672px;
$tablet-landscape-width: 992px;
$tablet-width: 768px;
$desktop-width: 1152px;
$wide-desktop-width: 1500px;

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin lt-tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin gt-tablet {
  @media (min-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (max-width: #{$tablet-landscape-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin gt-mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin lt-desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin lt-wide-desktop {
  @media (max-width: #{$wide-desktop-width}) {
    @content;
  }
}

@mixin mobile-small {
  @media (max-width: #{$mobile-small-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}
