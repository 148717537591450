@font-face {
  font-family: 'Ftn-Book-45';
  src: url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe1d4290c43533c6ac1_ftn45-webfont.woff2')
      format('woff2'),
    url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe198277c3ecbb7a6f6_ftn45-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ftn-Light-35';
  src: url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe288eab3730ff1cca4_ftn35-webfont.woff2')
      format('woff2'),
    url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe1beaa975f99057bed_ftn35-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ftn-Medium-55';
  src: url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe2f890db025779c2e9_ftn55-webfont.woff2')
      format('woff2'),
    url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe212e1d170e369d016_ftn55-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ftn-Semi-65';
  src: url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe2a5d3090ce45205e9_ftn65-webfont.woff2')
      format('woff2'),
    url('https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5dbe2f047b407934c0dfa_ftn65-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circularstd';
  src: url('https://assets.website-files.com/5e204ef6cb65fa285b5a04fb/5e2189bc20c003b56bd29d7a_CircularStd-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Circularstd';
  src: url('https://assets.website-files.com/5e204ef6cb65fa285b5a04fb/5e2189bc4c48fe328609c2ca_CircularStd-Bold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Circularstd';
  src: url('https://assets.website-files.com/5e204ef6cb65fa285b5a04fb/5e2189760f868d36d989c4a8_CircularStd-Book.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}
