.signup-modal-content {
  border-radius: 0rem !important;
  padding: 32px;
}

.signup-modal-backdrop {
  opacity: 0.85 !important;
}

.signup-modal {
  max-width: 568px !important;
  width: 100% !important;
  font-family: Lato;
}
@include media-breakpoint-down(xs) {
  .signup-modal {
    margin: 0px;
  }
  .signup-modal-content {
    min-height: 100vh;
  }
}

.signup-modal-header .close {
  font-size: 2.5rem;
  font-weight: 300;
  @extend .align-middle;
}

.signup-modal-header {
  border-bottom: 0px !important;
}

.signup-modal-header .modal-title {
  font-weight: 300;
  font-size: 20px;
}

.signup-input {
  width: 100%;
  padding: 6px;
  font-family: Lato;
  font-weight: 300;
  font-size: 19px;
  border: 0px;
  background-color: inherit;
  outline: none;
  color: black;
}
.signup-input::placeholder {
  font-weight: 300 !important;
}

.signup-input-div {
  border: 1px;
  border-color: rgb(219, 219, 219);
  border-style: solid;
  margin-bottom: 15px;
}
.signup-input-div-active {
  border: 1px;
  border-color: rgb(219, 219, 219);
  border-style: solid;
  margin-bottom: 15px;
}
.signup-input-div-error {
  border: 1px;
  border-color: rgb(252, 100, 45);
  border-style: solid;
  background-color: rgb(255, 248, 246);
  margin-bottom: 15px;
}

.signup-button {
  font-size: 19px;
  background-color: #00cfcc;
  padding: 10px;
}
.signup-button-previous {
  font-family: 'Work Sans';
  font-size: 19px;
  padding: 10px;
}

.signup-input-error-div {
  font-size: 14px;
  font-weight: 300;
  color: rgb(217, 57, 0);
  margin-bottom: 5px;
}

.signup-checkbox-label {
  padding-left: 8px;
  font-family: Lato;
  font-weight: 300;
  font-size: 19px;
}

.signup-checkbox-div {
  margin-bottom: 16px;
}

.signup-checkbox {
  top: 0px;
  width: 18px;
  height: 18px;
  border-radius: 0px;
}

.signup-places {
  position: relative;
}
.signup-places .autocomplete-dropdown-container {
  position: absolute;
  z-index: 1001;
  width: 100%;
  border: 1px solid lightgrey;
  font-size: 19px;
  font-family: Lato;
  font-weight: 300;
}

.signup-places .suggestion-item,
.suggestion-item--active {
  padding: 10px;
}

.signup-complete h2 {
  font-family: 'Work Sans';
  font-weight: 300;
}

.signup-complete h4 {
  font-family: Lato;
  font-weight: 300;
}
