$max-width: 73rem;

.limit-width {
  max-width: $max-width;
  margin: auto;
}

.max-width {
  width: 100%;

  @include desktop {
    width: 80%;
    margin: auto;
  }
}

@mixin common-layout-classes($breakpoint: '') {
  .full-height#{$breakpoint} {
    height: 100%;
  }

  .full-width#{$breakpoint} {
    width: 100%;
  }

  .hide#{$breakpoint} {
    display: none;
  }

  .text-center#{$breakpoint} {
    text-align: center;
  }

  .text-right#{$breakpoint} {
    text-align: right;
  }

  .pull-right#{$breakpoint} {
    float: right;
  }

  .flex#{$breakpoint} {
    flex: 1;
  }

  .flex-row#{$breakpoint} {
    display: flex;
    flex-direction: row;
  }

  .flex-col#{$breakpoint} {
    display: flex;
    flex-direction: column;
  }

  .flex-row-reverse#{$breakpoint} {
    display: flex;
    flex-direction: row-reverse;
  }

  .flex-wrap#{$breakpoint} {
    flex-wrap: wrap;
  }

  .flex-hs#{$breakpoint} {
    justify-content: flex-start;
  }

  .flex-he#{$breakpoint} {
    justify-content: flex-end;
  }

  .flex-hc#{$breakpoint} {
    justify-content: center;
  }

  .flex-hb#{$breakpoint} {
    justify-content: space-between;
  }

  .flex-ha#{$breakpoint} {
    justify-content: space-around;
  }

  .flex-vt#{$breakpoint} {
    align-items: flex-start;
  }

  .flex-vb#{$breakpoint} {
    align-items: flex-end;
  }

  .flex-vc#{$breakpoint} {
    align-items: center;
  }

  .flex-vs#{$breakpoint} {
    align-items: stretch;
  }

  .flex-av#{$breakpoint}  {
    align-content: space-between;
  }
}

@include common-layout-classes;

@include tablet {
  @include common-layout-classes('-tablet');
}

@include tablet-landscape {
  @include common-layout-classes('-tablet-landscape');
}

@include lt-tablet {
  @include common-layout-classes('-lt-tablet');
}

@include gt-tablet {
  @include common-layout-classes('-gt-tablet');
}

@include mobile {
  @include common-layout-classes('-mobile');
}

@include mobile-small {
  @include common-layout-classes('-mobile-small');
}

@include desktop {
  @include common-layout-classes('-desktop');
}

@include gt-mobile {
  @include common-layout-classes('-gt-mobile');
}

@include lt-desktop {
  @include common-layout-classes('-lt-desktop');
}
