$purple: #302654;
$teal: #00cfcc;
$secondary1: #85807a;
$secondary2: #d9d1c2;
$secondary3: #e5ded6;
$secondary4: #ff6657;

$color-map: (
  'black': #000000,
  'white': #ffffff,
  'white-ghost': #f5f5f5,
  'grey': #85898f,
  'grey-100': #fbfbfb,
  'grey-150': #fafbfc,
  'grey-200': #e1e4ea,
  'grey-300': #e7e9f1,
  'grey-400': #ccd1d8,
  'grey-500': #d8dee6,
  'grey-600': #9fa5ad,
  'grey-700': #85898f,
  'grey-800': #474747,
  'grey-900': #212121,
  'green': #00d187,
  'dark-blue': #444fff,
  'mid-blue': #00a1ff,
  'light-blue': #00d7ff,
  'upandup-purple': $purple !important,
  'upandup-teal': $teal !important,
  'homevest-purple': #916bff !important,
  'secondary1': $secondary1 !important,
  'secondary2': $secondary2 !important,
  'secondary3': $secondary3 !important,
  'secondary4': $secondary4 !important
);

@mixin color-classes($color-name, $color) {
  .color-#{$color-name} {
    color: $color;
  }

  .color-bg-#{$color-name} {
    background-color: $color;
  }

  .color-bd-#{$color-name} {
    border: 1px solid $color;
  }

  .color-bdt-#{$color-name} {
    border-top: 1px solid $color;
  }

  .color-bdb-#{$color-name} {
    border-bottom: 1px solid $color;
  }

  .color-bdl-#{$color-name} {
    border-left: 1px solid $color;
  }

  .color-bdh-#{$color-name} {
    border-left: 1px solid $color;
    border-right: 1px solid $color;
  }

  .color-bdv-#{$color-name} {
    border-bottom: 1px solid $color;
    border-top: 1px solid $color;
  }
}

@each $color-name, $color in $color-map {
  @include color-classes($color-name, $color);
}
