@mixin ww-btn() {
  @extend .text-center;

  padding: 0.5rem 1.5rem 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.35px;
  color: #fff;
}

@mixin btn-effects-background($color: 'black', $value: 5%) {
  &:hover {
    background: lighten(map_get($color-map, $color), $value);
  }
  &:focus {
    background: darken(map_get($color-map, $color), $value);
    text-decoration: none;
  }
  &.inactive {
    opacity: 0.3;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.ww-btn-primary {
  @include ww-btn;
  @include btn-effects-background;
  @extend .color-bg-black;
}
